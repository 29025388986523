import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, Form, Typography } from '@common/components/';
import { isEmpty, refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import useShowPDF from '@util/hook/useShowPDF';
import terms from '@icoach/documents/cyclePurchase/terms';
import { DOCUMENT_CYCLE_PURCHASE_PATH, DOCUMENT_CYCLE_PURCHASE_ROUTER } from '@icoach/router/routerPath';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import {
    ClubSection,
    ConsumerSection,
    InvoiceSection,
    PaymentSection,
    ProductSection,
    ReceiverSection,
    ShippingSection,
    TermSection,
} from '@icoach/documents/cyclePurchase/components';
import { parseQueryStringToObject } from '@icoach/rentCabinet/staticData';
import useValidationErrors from '@util/hook/useValidationErrors';
import useInactivityTimerToggle from '@util/hook/useInactivityTimerToggle';
import { FocusModeToggle } from '@icoach/components';

const CyclePurchasePage = (props) => {
    const history = useHistory();
    const showPDF = useShowPDF();
    const search = props.location.search;
    const { getInitCyclePurchaseApi, postCyclePurchaseOrderApi, postTemporaryCycleOrderApi } = useCyclePurchaseApi();
    const [memberID, setMemberID] = useState(0);
    const [contractData, setContractData] = useState({});
    const [effectDate, setEffectDate] = useState(parseDate()); // 訂單生效日
    const [options, setOptions] = useState({});
    const { validationErrors, updateValidationErrors, clearValidationErrors } = useValidationErrors();
    const consumerRef = useRef(null);
    const productRef = useRef(null);
    const shippingRef = useRef(null);
    const receiverRef = useRef(null);
    const paymentRef = useRef(null);
    const invoiceRef = useRef(null);
    const termRef = useRef(null);
    const clubRef = useRef(null);
    const apiSendingRef = useRef(null);
    const focusModeRef = useRef(null);

    useInactivityTimerToggle();

    const getParams = () => {
        let result = {
            cycleOrderType: 1, // 目前只有正常配送
        };
        if (consumerRef.current && consumerRef.current.getResult) result = Object.assign(result, consumerRef.current.getResult());
        if (productRef.current && productRef.current.getResult) result = Object.assign(result, productRef.current.getResult());
        if (shippingRef.current && shippingRef.current.getResult) result = Object.assign(result, shippingRef.current.getResult());
        if (receiverRef.current && receiverRef.current.getResult) result = Object.assign(result, receiverRef.current.getResult());
        if (paymentRef.current && paymentRef.current.getResult) result = Object.assign(result, paymentRef.current.getResult());
        if (invoiceRef.current && invoiceRef.current.getResult) result = Object.assign(result, invoiceRef.current.getResult());
        if (termRef.current && termRef.current.getResult) result = Object.assign(result, termRef.current.getResult());
        if (clubRef.current && clubRef.current.getResult) result = Object.assign(result, clubRef.current.getResult());
        result['cycleOrderID'] = contractData?.cycleOrderID ?? 0;
        return result;
    };

    const checkError = () => {
        return refIsRequiredError(consumerRef, productRef, shippingRef, receiverRef, paymentRef, invoiceRef, termRef, clubRef);
    };

    const handleSubmit = () => {
        if (apiSendingRef.current) return false;
        clearValidationErrors();
        const isError = checkError();

        if (!isError) {
            const params = getParams();

            doCyclePurchaseOrderApi(params);
        }
    };

    const handleTemporaryCycleOrder = () => {
        if (apiSendingRef.current) return false;
        const params = getParams();
        if (!refIsRequiredError(consumerRef)) {
            apiSendingRef.current = true;
            doTempCyclePurchaseApi(params, contractData?.cycleOrderID ?? 0);
        }
    };

    const doInitCyclePurchaseApi = async () => {
        let resp = await getInitCyclePurchaseApi();
        if (resp) {
            const { products, resource } = resp;
            setOptions({
                products,
                ...resource,
            });
        }
    };

    // 暫存循環店訂單
    const doTempCyclePurchaseApi = async (params, cycleOrderID) => {
        const resp = await postTemporaryCycleOrderApi(params, cycleOrderID);
        if (resp) {
            history.replace(DOCUMENT_CYCLE_PURCHASE_ROUTER);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 確認送出
    const doCyclePurchaseOrderApi = async (params) => {
        apiSendingRef.current = true;
        const { memberID } = params;
        const resp = await postCyclePurchaseOrderApi(params);
        const { isError = false, result, contractID, completedPath } = resp || {};

        apiSendingRef.current = false;

        if (isError) {
            // 0002 錯誤
            updateValidationErrors(result);
        } else if (isEmpty(resp)) {
            // 失敗
        } else {
            // 成功
            // 開啟PDF
            showPDF({
                open: true,
                pdfUrl: completedPath,
            });
            // // 轉跳訂單資料頁 唯讀
            window.setTimeout(() => {
                history.push(DOCUMENT_CYCLE_PURCHASE_PATH(memberID, contractID));
            }, 10);
        }
    };

    useEffect(
        () => {
            doInitCyclePurchaseApi();
            // 有待參數進入頁面
            if (search) {
                let { memberID } = parseQueryStringToObject(search);
                if (memberID) {
                    setMemberID(memberID);
                }
            }
        },
        // eslint-disable-next-line
        [],
    );
    return (
        <Box className="container main-container-spacing cycle-purchase-page">
            <Form onSubmit={handleSubmit}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        健康產品循環訂購單
                    </Typography>
                </Box>
                <FocusModeToggle ref={focusModeRef}>
                    <Card className={'order-info-card mb-4'}>
                        {/* 訂購人資訊 */}
                        <ConsumerSection
                            ref={consumerRef}
                            sourceData={contractData}
                            setContractData={setContractData}
                            memberID={memberID}
                            errorModule={validationErrors}
                        />
                        {/* 訂購商品」 */}
                        <ProductSection
                            ref={productRef}
                            options={options}
                            sourceData={{ items: contractData.items || [], effectType: contractData.effectType }}
                            errorModule={validationErrors}
                            isCreate
                        />
                        {/* 出貨資訊 */}
                        <ShippingSection
                            ref={shippingRef}
                            sourceData={{
                                shippingDay: contractData.shippingDay,
                                effectType: contractData.effectType,
                                firstShipmentMonth: contractData.firstShipmentMonth,
                            }}
                            effectDate={effectDate}
                            options={options}
                            errorModule={validationErrors}
                            isCreate
                        />
                        {/* 收件人資訊 */}
                        <ReceiverSection
                            ref={receiverRef}
                            sourceData={contractData}
                            membershipData={contractData?.defaultData ?? {}}
                            errorModule={validationErrors}
                            isCreate
                        />
                        <React.Fragment key={contractData?.memberID}>
                            {/* 付款資訊 */}
                            <PaymentSection
                                ref={paymentRef}
                                sourceData={contractData}
                                membershipData={contractData?.defaultData ?? {}}
                                errorModule={validationErrors}
                                isCreate
                            />
                            {/* 發票資訊 */}
                            <InvoiceSection
                                ref={invoiceRef}
                                sourceData={contractData.invoice || {}}
                                membershipData={contractData?.defaultData ?? {}}
                                options={options}
                                errorModule={validationErrors}
                                isCreate
                            />
                        </React.Fragment>
                    </Card>
                    {/* 訂購條款 */}
                    <TermSection className={'mb-4'} ref={termRef} data={terms} errorModule={validationErrors} />
                    {/* 店舖教練資訊 */}
                    <Card className={'order-info-card mb-4'}>
                        <ClubSection
                            ref={clubRef}
                            data={contractData}
                            resource={options}
                            effectDate={effectDate}
                            setEffectDate={setEffectDate}
                            errorModule={validationErrors}
                            isCreate
                        />
                        <Box className={'text-right btn-group px-4 pb-3'}>
                            <Button variant={'outlined'} onClick={handleTemporaryCycleOrder}>
                                暫存資料
                            </Button>
                            <Button type={'submit'} variant={'contained'}>
                                確認送出
                            </Button>
                        </Box>
                    </Card>
                </FocusModeToggle>
            </Form>
        </Box>
    );
};

export default CyclePurchasePage;
