import React, { useState, useLayoutEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import 'dayjs/locale/zh-tw';
import { PrivateRoute, IconButton } from '@common/components/';
import { Close as CloseIcon } from '@common/SvgIcon/';
import EmployeeLoginDialog from '@icoach/login/EmployeeLoginDialog';
import EmployeePasswordResetDialog from '@icoach/login/EmployeePasswordResetDialog';
import ShowLoading from '@root/scripts/components/loading/ShowLoading';
import MessageDialog from '@icoach/components/dialog/MessageDialog';
import LoginPage, { ClubResetAuthPage } from '@root/scripts/login';
import Home from '@root/scripts/home';
import AppVersion from '@root/AppVersion';
import { isEmpty } from '@util/utils';
import { LOGIN_ROUTER, LOGIN_COACH_ROUTER, ROOT_LOGIN_RESET_PASSWORD_TOKEN_ROUTER, LOGIN_COACH_RESET_PASSWORD_TOKEN_ROUTER } from '@icoach/router/routerPath';

import '@scss/site.scss';
import '@scss/icoach/index.scss';

const ENTRANCE_MAP = {
    CLUBLOGIN: 100,
    EMPLOYEELOGIN: 200,
    EMPLOYEELOGINRESETPASSWORD: 300,
};

const getRoutes = (entrance) => {
    switch (entrance) {
        case ENTRANCE_MAP.CLUBLOGIN:
            return <Route exact path={LOGIN_ROUTER} component={LoginPage} />;
        case ENTRANCE_MAP.EMPLOYEELOGIN:
            return <Route exact path={LOGIN_COACH_ROUTER} component={EmployeeLoginDialog} />;
        case ENTRANCE_MAP.EMPLOYEELOGINRESETPASSWORD:
            return <Route exact strict path={LOGIN_COACH_RESET_PASSWORD_TOKEN_ROUTER} component={EmployeePasswordResetDialog} />;
        default:
            return null;
    }
};

const App = () => {
    const [entrance, setEntrance] = useState(null);
    const { pathname: rootPath = '' } = useLocation();
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    useLayoutEffect(
        () => {

            // 移除路徑末尾的多餘斜杠
            const sanitizedPath = rootPath.replace(/\/+$/, '');
            const [, rootSegment = '', subPath = '', action = ''] = sanitizedPath.split('/');
            switch (true) {
                case rootSegment === 'login' && isEmpty(subPath):
                    setEntrance(ENTRANCE_MAP.CLUBLOGIN);
                    break;

                case rootSegment === 'login' && subPath === 'coach' && isEmpty(action):
                    setEntrance(ENTRANCE_MAP.EMPLOYEELOGIN);
                    break;

                case rootSegment === 'login' && subPath === 'coach' && action === 'reset-password':
                    setEntrance(ENTRANCE_MAP.EMPLOYEELOGINRESETPASSWORD);
                    break;

                case rootSegment === 'club':
                    setEntrance(0);
                    break;

                default:
                    break;
            }
        },
        // eslint-disable-next-line
        [rootPath]
    );

    return (
        <React.Fragment>
            <SnackbarProvider
                className="snacker-massage"
                ref={notistackRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                )}
            >
                <AppVersion />
                <Switch>
                    {getRoutes(entrance)}
                    <Route exact strict path={ROOT_LOGIN_RESET_PASSWORD_TOKEN_ROUTER} component={ClubResetAuthPage} />
                    <PrivateRoute as={Home} />
                </Switch>
            </SnackbarProvider>
            <MessageDialog />
            <ShowLoading />
        </React.Fragment>
    );
};

export default App;
