import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Checkbox, Chip, Typography } from '@common/components/';
import CustomerNameLabel from '@icoach/nonMember/components/CustomerNameLabel';
import { DescribeLabelBox } from '@icoach/components';
import { parseDate } from '@util/moment';
import { isEmpty } from '@util/utils';

export const getCustomerRowsLabel = (isRevealed, toggle) => [
    {
        cellKey: 'customerID',
        headerLabel: '',
        isIcon: true,
        formatCell: (cellValue, rowData, { chkRef, checkedOnChange }) => {
            return (
                <Checkbox
                    color="primary"
                    onChange={checkedOnChange}
                    value={cellValue}
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                            chkRef.current.push(el);
                        }
                    }}
                />
            );
        },
    },
    {
        cellKey: '',
        headerLabel: '顧客',
        width: 14,
        maxWidth: 22,
        align: 'left',
        formatCell: (cellValue, rowData) => {
            return <CustomerNameLabel sourceData={rowData} isRevealed={isRevealed} onToggle={toggle} />;
        },
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        width: 12,
        maxWidth: 12,
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} color="warning" />;
        },
    },
    {
        cellKey: 'requiredTypeText',
        headerLabel: '需求',
        width: 12,
        maxWidth: 12,
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} color="success" />;
        },
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        width: 12,
        maxWidth: 12,
        align: 'center',
        formatCell: (cellValue, rowData) => {
            const isInvalid = String(rowData.status) === '2';
            return <Chip label={cellValue} color={clsx(isInvalid ? 'gray' : 'info')} />;
        },
    },
    {
        cellKey: 'contact',
        headerLabel: '聯繫',
        align: 'left',
        minWidth: 13,
        maxWidth: 15,
        formatCell: (cellValue, rowData, { setContactState }) => {
            return isEmpty(cellValue) ? (
                <DescribeLabelBox {...cellValue} targetID={rowData.customerID} peopelType={'1'} setContactState={setContactState} />
            ) : (
                ''
            );
        },
    },
    {
        cellKey: 'introducer',
        headerLabel: '介紹人',
        align: 'center',
        style: { width: 100 },
    },
    {
        cellKey: 'askDate',
        headerLabel: '詢問日期',
        align: 'center',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'body1'} className={'mb-0'}>
                    {parseDate(cellValue, 'YYYY/MM/DD')}
                </Typography>
            );
        },
    },
];

export const getProspectRowsLabel = (isRevealed, toggle) => [
    {
        cellKey: 'customerID',
        headerLabel: ' ',
        isIcon: true,
        formatCell: (cellValue, rowData, { chkRef, checkedOnChange }) => {
            return (
                <Checkbox
                    color="primary"
                    onChange={checkedOnChange}
                    value={cellValue}
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                            chkRef.current.push(el);
                        }
                    }}
                />
            );
        },
    },
    {
        cellKey: 'customer',
        headerLabel: '名單',
        width: 14,
        maxWidth: 22,
        align: 'left',
        formatCell: (cellValue, rowData) => {
            return <CustomerNameLabel sourceData={rowData} isRevealed={isRevealed} onToggle={toggle} />;
        },
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        maxWidth: 8,
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} color="warning" />;
        },
    },
    {
        cellKey: 'requiredTypeText',
        headerLabel: '需求',
        maxWidth: 8,
        align: 'center',
        formatCell: (cellValue) => {
            return <Chip label={cellValue} color="success" />;
        },
    },
    {
        cellKey: 'contact',
        headerLabel: '聯繫',
        align: 'left',
        minWidth: 12,
        maxWidth: 15,
        formatCell: (cellValue, rowData, { setContactState }) => {
            return !_.isEmpty(cellValue) ? (
                <DescribeLabelBox {...cellValue} targetID={rowData.customerID} peopelType={'1'} setContactState={setContactState} />
            ) : (
                ''
            );
        },
    },
    {
        cellKey: 'introducer',
        headerLabel: '介紹人',
        maxWidth: 6,
        align: 'center',
    },
    {
        cellKey: 'registrationDate',
        headerLabel: '登錄日期',
        align: 'center',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'body1'} className={'mb-0'}>
                    {parseDate(cellValue, 'YYYY/MM/DD')}
                </Typography>
            );
        },
    },
];
