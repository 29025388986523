import React from 'react';
import { Typography } from '@common/components/';

const PasswordAgeWarningTextBox = React.forwardRef((props, ref) => {
    return (
        <React.Fragment>
            <Typography variant="h3" className="text-center" color="error">
                店舖密碼需要更新！
            </Typography>
            <Typography component={'div'} variant="h5" className="text-center my-3">
                您的帳戶密碼即將到期。建議您儘快修改密碼。
            </Typography>
            <Typography component={'div'} variant="h5" className="text-center my-3">
                保護帳戶安全，從現在開始。
            </Typography>
        </React.Fragment>
    );
});

export default PasswordAgeWarningTextBox;
